import navMenuItems from '@/navigation/default.supervizr' // Importez les éléments du menu
import navMenuItemsSgsSupervizr from '@/navigation/sgs.supervizr' // Importez les éléments du menu
import navMenuItemsSgsHypervizr from '@/navigation/sgs.hypervizr' // Importez les éléments du menu
import navMenuItemsGtechSupervizr from '@/navigation/gtech.supervizr' // Importez les éléments du menu
import { capitalizeFirstLetter } from '@/libs/formatting'
import store from "@/store"; // Importez la fonction utilitaire
import router from '@/router'
function getSubDomain() {
  let host = window.location.hostname
  host=host.replace('http://','')
  host=host.replace('https://','')
  const parts = host.split('.')
  let sub=""
  if (parts.length > 2) {
    sub= parts[0]
  }
  return sub

}

function getDomain() {
  const host = window.location.hostname
  console.log('host ===>',window.location);
  const parts = host.split('.')
  let result=host;
  if (parts.length > 1) {
    result= parts.slice(parts.length - 2).join('.')
  }else{
    result=host;
  }
  return result;
}
function getProtocol() {
  return window.location.protocol;
}
function getPort() {
  return window.location.port;
}

function getBaseUrl() {
  let subDomain=getSubDomain()
  let domain=getDomain()
  let protocol=getProtocol()
  let port=getPort()
  let baseUrl = `${protocol}//${subDomain}.${domain}${port?':'+port:''}`
  return baseUrl
}
function getBaseApiUrl() {
  let subDomain=getSubDomain()
  let domain=getDomain()
  let protocol=getProtocol()
  let port=getPort()
  domain="supervizr.net"
  let baseUrl = `${protocol}//api.${domain}${port?':'+port:''}/${subDomain}`
  return baseUrl
}
function getBaseAssetUrl() {
  let subDomain=getSubDomain()
  let domain=getDomain()
  let protocol=getProtocol()
  let port=getPort()
  let baseUrl = `${protocol}//api.${domain}${port?':'+port:''}`
  return baseUrl
}
function getBaseApiUrlLocal() {
  let subDomain=getSubDomain()
  let baseUrl = `http://127.0.0.1:8001/sgs`
  // let baseUrl = `https://api.supervizr.net/sgs`
  return baseUrl
}
// Fonction pour filtrer récursivement les enfants et les éléments principaux
function filterMenuItems(items, userPermissions) {

  return items.reduce((filtered, item) => {
    // Filtrage des enfants
    const filteredChildren = item.submenu ? filterMenuItems(item.submenu, userPermissions) : [];
    // Déterminer si l'élément principal doit être inclus
    const isVisible = userPermissions.includes(item.permission) || filteredChildren.length > 0;
    if (isVisible) {
      const filteredItem = { ...item };
      // Si des sous-menus sont présents et filtrés, les assigner
      if (filteredChildren.length > 0) {
        filteredItem.submenu = filteredChildren;
        // Vérifier si l'un des sous-menus est actif
        const hasActiveChild = filteredChildren.some(child => child.active);
        if (hasActiveChild) {
          filteredItem.active = true;
        }
      }

      // Appliquer le formatage des titres
      if (filteredItem.text) {
        filteredItem.text = capitalizeFirstLetter(filteredItem.text);
      }
      if (filteredItem.header) {
        filteredItem.header = capitalizeFirstLetter(filteredItem.header);
      }
      if (filteredItem.route) {
        try {
          let routeInfo = router.resolve({ name: filteredItem.route });
          filteredItem.link = routeInfo.href || routeInfo.route.path;
          const currentPath = window.location.pathname;

          if (currentPath === filteredItem.link) {
            filteredItem.active = true;
          } else {
            filteredItem.active = filteredItem.active || false; // Conserver la valeur actuelle si elle est déjà `true`
          }
        } catch (e) {
          // Gérer les erreurs si nécessaire
          filteredItem.active = filteredItem.active || false;
        }
      } else {
        filteredItem.active = filteredItem.active || false;
      }

      if (filteredItem.submenu) {
        filteredItem.submenu = filteredItem.submenu.map(child => ({
          ...child,
          title: child.text ? capitalizeFirstLetter(child.text) : undefined
        }));
      }
      filtered.push(filteredItem);
    }

    return filtered;
  }, []);
}

function importNavMenuItemsByDomain() {

  let url=`${getSubDomain()}.${getDomain()}`
  url='gtech.supervizr.net'
  let menuItems= {
    'default':navMenuItems,
    'sgs.supervizr.net':navMenuItemsSgsSupervizr,
    'sgs.hypervizr.net':navMenuItemsSgsHypervizr,
    'gtech.supervizr.net':navMenuItemsGtechSupervizr,
  };
  let menuItem=menuItems.default;
  if( Object.keys(menuItems).includes(url) ){
    menuItem=menuItems[url];
  }

  return menuItem
}
export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    subDomain: getSubDomain(),
    domain: getDomain(),
    protocol: getProtocol(),
    port: getPort(),
    baseUrl: getBaseUrl(),
    baseAssetUrl: getBaseAssetUrl(),
    baseApiUrl: getBaseApiUrl(),
    baseApiUrlLocal: getBaseApiUrlLocal(),
    user: {
      id: null,
      firstName: '',
      lastName: '',
      email: '',
      token: null,
      permissions: [] // Permissions utilisateur
    },
    menuItems: importNavMenuItemsByDomain() // Utilisez les éléments du menu importés
  },
  getters: {
    subDomain: state => state.subDomain,
    domain: state => state.domain,
    protocol: state => state.protocol,
    baseUrl: state => state.baseUrl,
    baseAssetUrl: state => state.baseAssetUrl,
    baseApiUrl: state => state.baseApiUrl,
    baseApiUrlLocal: state => state.baseApiUrlLocal,
    userId: state => state.user.id,
    userFirstName: state => state.user.firstName,
    userLastName: state => state.user.lastName,
    userEmail: state => state.user.email,
    userToken: state => state.user.token,
    userPermissions: state => state.user.permissions,
    menuItems: state => state.menuItems,
    filteredMenuItems: (state, getters) => {
      return filterMenuItems(state.menuItems, getters.userPermissions)
    }
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_USER_ID(state, id) {
      state.user.id = id
    },
    SET_USER_FIRST_NAME(state, firstName) {
      state.user.firstName = firstName
    },
    SET_USER_LAST_NAME(state, lastName) {
      state.user.lastName = lastName
    },
    SET_USER_EMAIL(state, email) {
      state.user.email = email
    },
    SET_USER_TOKEN(state, token) {
      state.user.token = token
    },
    SET_USER_PERMISSIONS(state, permissions) {
      state.user.permissions = permissions
    },
    UPDATE_MENU_ITEMS(state, menuItems) { // Mutation pour mettre à jour les éléments du menu
      state.menuItems = menuItems
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('SET_USER_ID', user.id)
      commit('SET_USER_FIRST_NAME', user.firstName)
      commit('SET_USER_LAST_NAME', user.lastName)
      commit('SET_USER_EMAIL', user.email)
      commit('SET_USER_TOKEN', user.token)
      commit('SET_USER_PERMISSIONS', user.permissions)
    },
    updateMenuItems({ commit }, menuItems) {
      commit('UPDATE_MENU_ITEMS', menuItems)
    }
  },
}
